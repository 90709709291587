import ng from 'angular';

// @ngInject
function states($stateProvider, nsUtilProvider, acpModalStateProvider) {
  // @ngInject
  function onEnterOrderActivate(
    PermTransitionProperties,
    $q,
    acpAnalytics,
    acpFeeplanModel,
    acpOverdraftDomainModel
  ) {
    if (PermTransitionProperties.options.fromSidenav) {
      // Google Analytics tracking for DDA upgrade
      $q.all({
        gaOverdraft: acpOverdraftDomainModel.isOverdraftOptedIn(),
        gaFeePlan: acpFeeplanModel.getFeePlanName()
      }).then(function (gaData) {
        acpAnalytics.track(gaData);
      });
    }
  }

  function scrollToTop() {
    var scrollElement = document.getElementById('dda-flow-interstitial');
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }

  $stateProvider
    // The DDA Order and Activate parent state
    .state('upgrade', {
      parent: 'root',
      redirectTo: '.learn-more',
      url: '',
      template: '<ui-view />',
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.apps.upgrade" */ 'apps/upgrade'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    // The DDA Order learn more page, parent of the order flow
    .state('upgrade.learn-more', {
      url: '/upgrade/learn-more',
      templateUrl: 'apps/upgrade/templates/acp-upgrade-learn-more.ng.html',
      data: {
        permissions: {
          only: ['dda_order_eligible', 'bdda_order_eligible'],
          redirectTo: {
            dda_order_eligible: 'dashboard'
          }
        }
      },
      // We use this as a hook for sending additional GA info
      onEnter: onEnterOrderActivate,
      resolve: {
        pageTitle: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/upgrade', 'page-title.learn-more')
          );
        },
        $feePlan: /* @ngInject */ function (module, acpUpgradeFeeplanModel) {
          return acpUpgradeFeeplanModel.getFeeplan();
        },
        $pewUrl: /* @ngInject */ function (acpTermsModel) {
          return acpTermsModel
            .getUpgradeTermsUrl({ type: 'account' })
            .then(function (data) {
              return data.pew_url;
            });
        },
        $showHeaderImage: /* @ngInject */ function (nsPermissions) {
          return nsPermissions.requestPermission(
            'showUpgradeLearnMoreHeaderImage'
          );
        },
        $showUseNotice: /* @ngInject */ function (nsPermissions) {
          // This might deserve its own element in the future
          return nsPermissions.requestPermission(
            'productUpgradeDisplayFeatureComparisonTableTitle'
          );
        }
      }
    })

    //
    // The DDA Order flow parent (dialog with blur)
    //
    // This state has the steps count that determine what child pages in what order.
    // It also gates the entry to this order flow by requiring the `allow` param to be
    // passed when entering the state. If not, they get kicked back to the first step.
    .state(
      'upgrade.learn-more.modal',
      ng.extend(acpModalStateProvider.createModalState('$mdDialog'), {
        templateUrl: 'apps/upgrade/templates/acp-upgrade-modal.ng.html'
      })
    )
    .state(
      createStepFlowState(
        'upgrade.learn-more.modal.order',
        {
          url: '/order',
          data: {
            backdropColor: 'white-transparent'
          },
          resolve: {
            $hasSecondaryAccounts: /* @ngInject */ function (nsPermissions) {
              return nsPermissions
                .requestPermissions(['hasSecondaryCardHolders'])
                .then(function (data) {
                  return data.hasSecondaryCardHolders;
                });
            },
            $$termUrls: /* @ngInject */ function ($q, acpTermsModel) {
              return $q.all({
                esign: acpTermsModel
                  .getUpgradeTermsUrl({ type: 'esign' })
                  .then(getTermsUrl),
                terms: acpTermsModel
                  .getUpgradeTermsUrl({ type: 'account' })
                  .then(getTermsUrl),
                privacyPolicy: acpTermsModel
                  .getUpgradeTermsUrl({ type: 'privacy_policy' })
                  .then(getTermsUrl)
              });

              function getTermsUrl(data) {
                return data.terms_url;
              }
            },
            $orderModel: /* @ngInject */ function (
              module,
              acpUpgradeOrderModel
            ) {
              return acpUpgradeOrderModel();
            },
            $survey: /* @ngInject */ function (module, acpSurveyDomainModel) {
              return acpSurveyDomainModel.getDdaSurvey();
            },
            $stepManagerLocals: /* @ngInject */ function (
              $survey,
              $hasSecondaryAccounts
            ) {
              return {
                $hasSecondaryAccounts: $hasSecondaryAccounts,
                $survey: $survey
              };
            }
          }
        },
        [
          // The relative child state name. Pagination is false to control progress bar visibility
          //TODO: remove this hack once a fix for having optional step as first step in steps is fixed - NERDS-3274
          {
            state: '.flow.first',
            pagination: false,
            when: function () {
              return false;
            }
          },
          {
            state: '.flow.secondary-alert',
            pagination: true,
            when: /* @ngInject */ function ($hasSecondaryAccounts) {
              return $hasSecondaryAccounts;
            }
          },
          {
            state: '.flow.choose-card',
            pagination: true,
            when: /* @ngInject */ function (nsPermissions) {
              return nsPermissions.requestPermission(
                'hasMultipleUpgradeCardBrands'
              );
            }
          },
          { state: '.flow.shipping-info', pagination: true },
          {
            state: '.flow.survey',
            pagination: true,
            when: /* @ngInject */ function ($survey) {
              return $survey.survey_id;
            }
          },
          {
            state: '.flow.esign-terms-combined',
            pagination: true
          },
          { state: '.completed' }
        ]
      )
    )
    .state('upgrade.learn-more.modal.order.flow', {
      templateUrl: 'apps/upgrade/templates/acp-upgrade-flow.ng.html',
      controller: /* @ngInject */ function (
        $scope,
        $stepManager,
        acpUpgradeLoggingModel
      ) {
        $scope.$on('$stateChangeSuccess', function () {
          scrollToTop();
          var currentStep = $stepManager.getCurrentStepIndex();
          if (currentStep >= 0) {
            $scope.currentStep = currentStep;
          }
          acpUpgradeLoggingModel().logFlowStarted();
        });
      }
    })
    //TODO: remove this hack once a fix for having optional step as first step in steps is fixed - NERDS-3274
    .state('upgrade.learn-more.modal.order.flow.first', {
      url: '/first',
      template: '<p></p>',
      data: {
        permissions: {
          only: ['hasSecondaryAccounts'],
          redirectTo: {
            hasSecondaryAccounts: /* @ngInject */ function (nsPermissions) {
              return nsPermissions
                .requestPermissions(['hasSecondaryCardHolders'])
                .then(function (data) {
                  //should not
                  if (data.hasSecondaryCardHolders) {
                    return 'upgrade.learn-more.modal.order.flow.secondary-alert';
                  } else {
                    return 'upgrade.learn-more.modal.order.flow.choose-card';
                  }
                });
            }
          }
        }
      }
    })
    .state('upgrade.learn-more.modal.order.flow.secondary-alert', {
      url: '/secondary-alert',
      template:
        '<acp-upgrade-secondary-alert continue="$resolve.$next()" exit="$resolve.$exit()"/>'
    })
    .state('upgrade.learn-more.modal.order.flow.loading', {
      template:
        '<div class="loading"><ns-progress-circular class="md-primary" ns-mode="indeterminate"></ns-progress-circular></div>',
      params: {
        nextState: null,
        nextParams: null
      },
      controller: /* @ngInject */ function ($state, $stateParams, $timeout) {
        // Immediately redirect and show loader until transition has finished
        $timeout(function () {
          $state.go($stateParams.nextState, $stateParams.nextParams, {
            location: 'replace'
          });
        });
      }
    })
    .state('upgrade.learn-more.modal.order.flow.choose-card', {
      url: '/choose-card',
      template:
        '<acp-upgrade-choose-card available-cards="$resolve.$availableCards" selected-card="$resolve.$orderModel.selectedCard" continue="$resolve.$next()" exit="$resolve.$exit()"/>',
      resolve: {
        $availableCards: /* @ngInject */ function (module, $orderModel) {
          return $orderModel.getCards();
        }
      },
      data: {
        permissions: {
          only: ['hasMultipleUpgradeCardBrands'],
          redirectTo: {
            hasMultipleUpgradeCardBrands:
              'upgrade.learn-more.modal.order.flow.shipping-info'
          }
        }
      }
    })
    .state('upgrade.learn-more.modal.order.flow.shipping-info', {
      url: '/shipping-info',
      template:
        '<acp-upgrade-confirm-shipping-info continue="$resolve.$next()" exit="$resolve.$exit()"/>'
    })
    .state('upgrade.learn-more.modal.order.flow.survey', {
      url: '/survey',
      template:
        '<acp-survey-order questions="$resolve.$survey.questions" answers="$resolve.$orderModel.surveyAnswers" continue="$resolve.$next()" exit="$resolve.$exit()"/>',
      resolve: {
        $orderModel: /* @ngInject */ function ($orderModel, $survey) {
          $orderModel.surveyId = $survey.survey_id;
          return $orderModel;
        }
      }
    })
    .state('upgrade.learn-more.modal.order.flow.esign-terms-combined', {
      url: '/esign-terms',
      template:
        '<acp-upgrade-esign-terms-combined esign-accepted="$resolve.$orderModel.esignAccepted" tax-identity="$resolve.$orderModel.taxIdentity" terms-accepted="$resolve.$orderModel.termsAccepted" irs-withholding="$resolve.$orderModel.irsWithholding" links="$resolve.$links" continue="$resolve.$next()" continue-button-text="esign-btn:components/upgrade-order" exit="$resolve.$exit()" back="$resolve.$previous()"/>',
      resolve: {
        $orderModel: /* @ngInject */ function ($orderModel, acpUserModel) {
          return acpUserModel.get().then(function (user) {
            $orderModel.esignEmail = user.primary_card_owner.email;
            return $orderModel;
          });
        },
        $links: /* @ngInject */ function ($$termUrls) {
          return {
            esign: $$termUrls.esign,
            privacyPolicy: $$termUrls.privacyPolicy,
            terms: $$termUrls.terms
          };
        }
      }
    })
    .state('upgrade.learn-more.modal.order.errors', {
      url: '/errors',
      template:
        '<acp-upgrade-errors errors="$resolve.$errors" exit="$resolve.$exit()"/>',
      data: {
        backdropColor: 'white-solid'
      },
      params: {
        errors: null
      },
      resolve: {
        $errors: /* @ngInject */ function ($stateParams) {
          return $stateParams.errors;
        }
      }
    })
    .state('upgrade.learn-more.modal.order.completed', {
      url: '/completed',
      template:
        '<acp-upgrade-complete-order order-model="$resolve.$orderModel" continue="$resolve.$exit()" />',
      data: {
        backdropColor: 'white-solid'
      }
    })
    // Is this going to be a activate page onto which we show the blurry modal?
    .state('upgrade.activate', {
      url: '',
      template: '<ui-view />'
    })
    // The DDA Activate flow parent (dialog with blur)
    //
    // This state has the steps count that determine what child pages in what order.
    // It also gates the entry to this order flow by requiring the `allow` param to be
    // passed when entering the state. If not, they get kicked back to the first step.
    .state(
      'upgrade.activate.modal',
      ng.extend(acpModalStateProvider.createModalState('$mdDialog'), {
        templateUrl: 'apps/upgrade/templates/acp-upgrade-modal.ng.html'
      })
    )
    .state(
      createStepFlowState(
        'upgrade.activate.modal.activate',
        {
          url: '/upgrade/activate',
          data: {
            backdropColor: 'white-transparent'
          },
          // We use this as a hook for sending additional GA info
          onEnter: onEnterOrderActivate,
          resolve: {
            $hasSecondaryAccounts: /* @ngInject */ function (nsPermissions) {
              return nsPermissions
                .requestPermissions([
                  'hasSecondaryCardHolders',
                  'isDdaActivationEligible'
                ])
                .then(function (data) {
                  return (
                    data.hasSecondaryCardHolders && data.isDdaActivationEligible
                  );
                });
            },
            $isIbaEligible: /* @ngInject */ function (nsPermissions) {
              return nsPermissions
                .requestPermissions(['isProductUpgradeIbaEligible'])
                .then(function (data) {
                  return data.isProductUpgradeIbaEligible;
                });
            },
            $terms: /* @ngInject */ function ($q, acpTermsModel) {
              return $q.all({
                esign: acpTermsModel.getUpgradeTermsUrl({ type: 'esign' }),
                terms: acpTermsModel.getUpgradeTermsUrl({ type: 'account' }),
                privacyPolicy: acpTermsModel.getUpgradeTermsUrl({
                  type: 'privacy_policy'
                }),
                irsWithholding: acpTermsModel.getUpgradeTermsUrl({
                  type: 'irs_withholding'
                }),
                taxIdentity: acpTermsModel.getUpgradeTermsUrl({
                  type: 'tax_identity'
                }),
                modelA9Terms: acpTermsModel.getUpgradeTermsUrl({
                  type: 'overdraft'
                }),
                anytimeAlertsTerms: acpTermsModel.getUpgradeTermsUrl({
                  type: 'alerts'
                })
              });
            },
            $surveyCompleted: /* @ngInject */ function (
              module,
              acpSurveyDomainModel
            ) {
              return acpSurveyDomainModel.isDdaSurveyCompleted();
            },
            $activationModel: /* @ngInject */ function (
              module,
              acpDdaActivationModel,
              $isIbaEligible,
              $hasSecondaryAccounts,
              $terms,
              $surveyCompleted
            ) {
              return acpDdaActivationModel({
                isIbaEligible: $isIbaEligible,
                hasSecondaryAccounts: $hasSecondaryAccounts,
                survey: $surveyCompleted,
                terms: $terms
              });
            },
            $stepManagerLocals: /* @ngInject */ function ($activationModel) {
              return {
                $activationModel: $activationModel
              };
            }
          }
        },
        //TODO: remove this hack once a fix for having optional step as first step in steps is fixed - NERDS-3274
        [
          {
            state: '.flow.first',
            pagination: false,
            when: function () {
              return false;
            }
          },
          {
            state: '.flow.secondary-alert',
            pagination: true,
            when: /* @ngInject */ function ($activationModel) {
              return $activationModel.hasSecondaryAccounts;
            }
          },
          { state: '.flow.pan-cvc', pagination: true },
          {
            state: '.flow.survey',
            pagination: true,
            when: /* @ngInject */ function ($activationModel) {
              return !$activationModel.surveyPreviouslyCompleted;
            }
          },
          { state: '.flow.confirm-terms', pagination: true },
          { state: '.flow.features', pagination: true },
          { state: '.activation-completed' }
        ]
      )
    )
    .state('upgrade.activate.modal.activate.flow', {
      templateUrl: 'apps/upgrade/templates/acp-upgrade-flow.ng.html',
      controller: /* @ngInject */ function (
        $scope,
        $stepManager,
        acpUpgradeLoggingModel
      ) {
        $scope.$on('$stateChangeSuccess', function () {
          scrollToTop();
          var currentStep = $stepManager.getCurrentStepIndex();
          if (currentStep >= 0) {
            $scope.currentStep = currentStep;
          }
          acpUpgradeLoggingModel().logFlowStarted();
        });
      }
    })
    .state('upgrade.activate.modal.activate.flow.loading', {
      template:
        '<div class="loading"><ns-progress-circular class="md-primary" ns-mode="indeterminate"></ns-progress-circular></div>',
      params: {
        nextState: null,
        nextParams: null
      },
      controller: /* @ngInject */ function ($state, $stateParams, $timeout) {
        $timeout(function () {
          // Immediately redirect and show loader until transition has finished
          $state.go($stateParams.nextState, $stateParams.nextParams, {
            location: 'replace'
          });
        });
      }
    })
    //TODO: remove this hack once a fix for having optional step as first step in steps is fixed - NERDS-3274
    .state('upgrade.activate.modal.activate.flow.first', {
      url: '/first',
      template: '<p></p>',
      data: {
        permissions: {
          only: ['isSecondaryAndActivationEligible'],
          redirectTo: {
            isSecondaryAndActivationEligible: /* @ngInject */ function (
              nsPermissions
            ) {
              return nsPermissions
                .requestPermissions([
                  'hasSecondaryCardHolders',
                  'isDdaActivationEligible',
                  'isCDDAEligibleSecondaryCardHolders',
                  'isBddaActivationEligible',
                  'isCddaEligibleAccountBalance',
                  'isCddaEligiblePendingDispute',
                  'isCddaEligibleLegalBlocks'
                ])
                .then(function (data) {
                  //should not
                  if (
                    !data.hasSecondaryCardHolders &&
                    (data.isDdaActivationEligible ||
                      data.isBddaActivationEligible)
                  ) {
                    return 'upgrade.activate.modal.activate.flow.pan-cvc';
                  }

                  if (
                    data.hasSecondaryCardHolders &&
                    (data.isDdaActivationEligible ||
                      data.isBddaActivationEligible)
                  ) {
                    return 'upgrade.activate.modal.activate.flow.secondary-alert';
                  }

                  if (!data.isCDDAEligibleSecondaryCardHolders) {
                    return {
                      state: 'upgrade.activate.modal.activate.errors',
                      params: {
                        errors: { message: { features: { secondary: false } } }
                      }
                    };
                  }

                  if (!data.isCddaEligibleAccountBalance) {
                    return {
                      state: 'upgrade.activate.modal.activate.errors',
                      params: {
                        errors: {
                          message: {
                            features: { cdda_eligible_account_balance: false }
                          }
                        }
                      }
                    };
                  }

                  if (!data.isCddaEligiblePendingDispute) {
                    return {
                      state: 'upgrade.activate.modal.activate.errors',
                      params: {
                        errors: {
                          message: {
                            features: { cdda_eligible_pending_dispute: false }
                          }
                        }
                      }
                    };
                  }

                  if (!data.isCddaEligibleLegalBlocks) {
                    return {
                      state: 'upgrade.activate.modal.activate.errors',
                      params: {
                        errors: {
                          message: {
                            features: { cdda_eligible_legal_blocks: false }
                          }
                        }
                      }
                    };
                  }

                  // if this is reached, that means there is an error
                  return 'upgrade.activate.modal.activate.errors';
                });
            }
          }
        }
      }
    })

    .state('upgrade.activate.modal.activate.flow.secondary-alert', {
      url: '/secondary-alert',
      template:
        '<acp-dda-activation-secondary-alert continue="$resolve.$next()" exit="$resolve.$exit()"/>'
    })
    .state('upgrade.activate.modal.activate.flow.pan-cvc', {
      url: '/pan-cvc',
      template:
        '<acp-dda-activation-enter-pan-cvc selected-card="$resolve.$activationModel.selectedCard" cards="$resolve.$cards" continue="$resolve.$next()" exit="$resolve.$exit()"/>',
      resolve: {
        $cards: /* @ngInject */ function ($activationModel) {
          return $activationModel.getCards();
        },
        $next: /* @ngInject */ function ($activationModel, $next) {
          return function () {
            //TODO show loading screen until this promise completes
            return $activationModel.verifyCard().then(function () {
              $next();
            });
          };
        }
      },
      data: {
        permissions: {
          only: 'product_upgrade_activation_eligible',
          redirectTo: {
            product_upgrade_activation_eligible: function (rejectedPermission) {
              return {
                state: 'upgrade.activate.modal.activate.errors',
                params: {
                  errors: rejectedPermission
                }
              };
            }
          },
          default: 'dashboard'
        }
      }
    })
    .state('upgrade.activate.modal.activate.flow.survey', {
      url: '/survey',
      template:
        '<acp-survey-order questions="$resolve.$survey.questions" answers="$resolve.$activationModel.surveyAnswers" continue="$resolve.$next()" exit="$resolve.$exit()"/>',
      resolve: {
        $survey: /* @ngInject */ function (module, acpSurveyDomainModel) {
          return acpSurveyDomainModel.getDdaSurvey();
        },
        $activationModel: /* @ngInject */ function ($activationModel, $survey) {
          $activationModel.surveyId = $survey.survey_id;
          return $activationModel;
        }
      }
    })
    .state('upgrade.activate.modal.activate.flow.confirm-terms', {
      url: '/confirm-terms',
      template:
        '<acp-dda-activation-confirm-ach links="$resolve.$links" confirmed-ach="$resolve.$activationModel.termsConfirmed" continue="$resolve.$next()" exit="$resolve.$exit()"/>',
      resolve: {
        $links: /* @ngInject */ function ($terms) {
          return {
            termsUrl: $terms.terms.terms_url,
            termsPewUrl: $terms.terms.pew_url,
            esign: $terms.esign.terms_url
          };
        },
        $next: /* @ngInject */ function ($activationModel, $next) {
          return function () {
            if ($activationModel.termsConfirmed) {
              $next();
            }
          };
        }
      }
    })
    .state('upgrade.activate.modal.activate.flow.features', {
      url: '/features',
      template:
        '<acp-dda-activation-aa-od-enrollment enroll-overdraft="$resolve.$activationModel.enroll_overdraft" enroll-alerts="$resolve.$activationModel.enroll_alerts" links="$resolve.$links" continue="$resolve.$next()" exit="$resolve.$exit()"/>',
      resolve: {
        $links: /* @ngInject */ function ($terms) {
          return {
            modelA9Link: $terms.modelA9Terms.terms_url,
            terms: $terms.terms.terms_url,
            anytimeAlertsTermsUrl: $terms.anytimeAlertsTerms.terms_url
          };
        }
      }
    })
    .state('upgrade.activate.modal.activate.activation-completed', {
      url: '/activation-completed',
      template:
        '<acp-upgrade-complete-activation activation-model="$resolve.$activationModel"></acp-upgrade-complete-activation>',
      data: {
        backdropColor: 'white-solid'
      }
    })
    .state('upgrade.activate.modal.activate.errors', {
      url: '/activation-errors',
      template:
        '<acp-upgrade-activation-errors exit="$resolve.$exit()" exit-button-text="$resolve.$exitButtonText"/>',
      data: {
        backdropColor: 'white-solid'
      },
      params: {
        errors: null
      },
      resolve: {
        $exitButtonText: /* @ngInject */ function () {
          return 'Back To My Account';
        }
      }
    });
}

function createStepFlowState(context, partial, steps) {
  /* @ngInject */
  function restartFlow($state, toParams) {
    return {
      state: $state.get('.flow.loading', context).name,
      params: ng.extend(
        {
          allow: true,
          nextState: $state.get(steps[0].state, context).name,
          nextParams: ng.extend({}, toParams, { allow: true })
        },
        toParams
      )
    };
  }

  return ng.extend(partial, {
    name: context,
    templateUrl: 'apps/upgrade/templates/acp-upgrade.ng.html',
    redirectTo: /* @ngInject */ function ($injector, toParams) {
      return $injector.invoke(restartFlow, null, { toParams: toParams });
    },
    controllerAs: 'vm',
    controller: /* @ngInject */ function ($state) {
      Object.defineProperty(this, 'stateData', {
        get: function () {
          return $state.current.data;
        }
      });
    },
    params: {
      allow: false // defaults to false
    },
    data: ng.extend(
      {
        permissions: {
          only: 'has_valid_allow_parameter',
          redirectTo: {
            has_valid_allow_parameter: /* @ngInject */ function (
              $injector,
              transitionProperties
            ) {
              return $injector.invoke(restartFlow, null, transitionProperties);
            }
          }
        },
        // Options for $mdDialog.show() since our parent Modal State use $mdDialog
        modalOptions: {
          // Hack for now to control blur of interstitial background
          onShowing: function () {
            ng.element(
              document.getElementById('ns-artisan-layout-view')
            ).addClass('blurred');
          },
          onRemoving: function () {
            ng.element(
              document.getElementById('ns-artisan-layout-view')
            ).removeClass('blurred');
          }
        }
      },
      partial.data
    ),
    resolve: ng.extend(
      {
        $stateContext: /* @ngInject */ function () {
          return context;
        },
        $steps: /* @ngInject */ function ($stepManager) {
          return $stepManager.getAllSteps();
        },
        // Can be overriden by parent
        $stepManagerLocals: ng.noop,
        $stepManager: /* @ngInject */ function (
          module,
          acpStepStateManager,
          $stateContext,
          $stepManagerLocals
        ) {
          return acpStepStateManager(steps, $stateContext, $stepManagerLocals);
        },
        $$goToStep: /* @ngInject */ function (
          $state,
          $stateContext,
          $stateParams
        ) {
          return function (step) {
            if (step) {
              $state.go(
                '.flow.loading',
                ng.extend(
                  {
                    nextState: step.name,
                    nextParams: ng.copy($stateParams)
                  },
                  $stateParams
                ),
                {
                  relative: $stateContext
                }
              );
            }
          };
        },
        $exit: /* @ngInject */ function (
          $state,
          acpAnalytics,
          acpUpgradeLoggingModel
        ) {
          return function () {
            var exitStep = $state.current.url;
            // We don't care about logging exits if the user is in an error state.
            if (exitStep.indexOf('errors') === -1) {
              acpUpgradeLoggingModel().logFlowCancelled({
                stepName: exitStep.replace(/\//g, ''),
                flowName: context
              });
            }
            acpAnalytics.sendEvent('DDA', 'upgradeExitStep', exitStep, true);
            $state.go('dashboard', null, { reload: 'dashboard' });
          };
        },
        $previous: /* @ngInject */ function ($window) {
          return function () {
            // Because we enforce starting at step 1, we can be sure that we have a good previous state to go to.
            // We cannot $state.go() anywhere as it will *add* a new history stack entry instead of popping
            $window.history.back();
          };
        },
        $next: /* @ngInject */ function ($$goToStep, $stepManager) {
          return function () {
            $$goToStep($stepManager.getStepState(1));
          };
        }
      },
      partial.resolve
    )
  });
}

export default states;
